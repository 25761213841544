<template>
	<div class="app-main__inner">
		<div class="container-fluid">
			<div class="container">
				<div class="row mb-2">
					<div class="col-md-3 mb-3">
						<div class="bg-white a-min-h rounded p-3 bg-custom-theme-blue">
							<div class="text-center text-b-color-theme font-semibold text-xs whitespace-nowrap">
								{{ $t('totalAccountant') }}
							</div>
							<div class="text-center mt-1">
							<span class="text-2xl text-b-color-theme font-semibold" id="total-accountant">{{
									$services.helpers.animateValue('total-accountant', 0, DASHBOARD_METRIC.total_accountants, 1000)
								}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-3">
						<div class="bg-white a-min-h rounded p-3 bg-theme-colord">
							<div class="text-center text-color-themed font-semibold text-xs whitespace-nowrap">
								{{ $t('connectedCustomer') }}
							</div>
							<div class="text-center mt-1">
							<span class="text-2xl text-color-themed font-semibold" id="connected-cus">{{
									$services.helpers.animateValue('connected-cus', 0, DASHBOARD_METRIC.total_customers, 1000)
								}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-3">
						<div class="bg-white a-min-h rounded p-3 bg-theme-blue">
							<div class="text-center text-color-theme-blue font-semibold text-xs whitespace-nowrap">
								{{ $t('totalSubscription') }}
							</div>
							<div class="text-center mt-1">
							<span class="text-2xl text-color-theme-blue font-semibold" id="total-subscrip">{{
									$services.helpers.animateValue('total-subscrip', 0, DASHBOARD_METRIC.total_subscriptions, 1000)
								}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-3">
						<div class="bg-white a-min-h rounded p-3 bg-theme-yellow">
							<div class="text-center text-color-theme-yellow font-semibold text-xs whitespace-nowrap">
								{{ $t('transactionBookkept') }}
							</div>
							<div class="text-center mt-1">
							<span class="text-2xl text-color-theme-yellow font-semibold" id="trans-bookkept">{{
									$services.helpers.animateValue('trans-bookkept', 0, DASHBOARD_METRIC.total_transactions, 1000)
								}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-md-3 mb-3">
						<div class="bg-white a-min-h rounded p-3 bg-theme-pink">
							<div class="text-center text-color-theme-pink font-semibold text-xs whitespace-nowrap">
								{{ $t('totalKickback') }}
							</div>
							<div class="text-center mt-1">
							<span class="text-2xl text-color-theme-pink font-semibold" id="total-kickback">{{
									$services.helpers.animateValue('total-kickback', 0, DASHBOARD_METRIC.total_kickbacks_and_discounts, 1000)
								}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-3">
						<div class="bg-white a-min-h rounded p-3 bg-theme-green">
							<div class="text-center text-color-theme-green font-semibold text-xs whitespace-nowrap">
								{{ $t('subscriptionType') }}
							</div>
							<div class="text-center mt-1">
							<span class="text-2xl text-color-theme-green font-semibold"
								  id="total-transac">{{ DASHBOARD_METRIC.affiliate_type }}</span>
							</div>
						</div>
					</div>
					<div class="col-md-3 mb-3">
						<div class="bg-white a-min-h rounded p-3 bg-theme-org">
							<div class="text-center text-color-theme-org font-semibold text-xs">{{
									$t('saveMoney')
								}}
							</div>
							<div class="text-center mt-1">
							<span class="text-2xl text-color-theme-org font-semibold" id="money-saved">{{
									$services.helpers.animateValue('money-saved', 0, DASHBOARD_METRIC.total_money_saved, 1000)
								}}</span>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="bg-white a-min-h rounded p-3 bg-theme-d-blue">
							<div class="text-center text-color-theme-d-blue font-semibold text-xs whitespace-nowrap">
								{{ $t('totalHours') }}
							</div>
							<div class="text-center mt-1">
							<span class="text-2xl text-color-theme-d-blue font-semibold" id="total-hours">{{
									$services.helpers.animateValue('total-hours', 0, DASHBOARD_METRIC.total_hours_saved, 1000)
								}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container">

				<div class="row justify-content-end mt-4">
					<div class="col-12 flex flex-col md:flex-row md:justify-between">
						<h2 class="text-xl font-medium text-gray-900">{{ $t('companies') }}</h2>
						<div>
						</div>
						<div class="flex md:justify-end">
							<div class="form-group">
								<div class="input-icons">
									<i class="iconly-brokenSearch" style="top: 2px;"></i>
									<input type="text" @input="searchInputTrigger"
										   class="text-sm h-8 form-control custom-search"
										   v-bind:placeholder="$t('search')">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="main-card mb-3 bg-white">
					<div class="card-body">
						<table style="width: 100%;" id="exampl" class="table table-hover table-striped table-bordered">
							<thead>
							<tr>
								<th>
									<div class="d-flex justify-content-between text-xs">{{ $t('companyName') }}<span><i
										@click="ascendingOrderName" class="lnr-arrow-up cursor-pointer"></i><i
										@click="descendingOrderName" class="lnr-arrow-down cursor-pointer"></i></span>
									</div>
								</th>
								<th>
									<div class="d-flex justify-content-between text-xs">{{
											$t('companyNumber')
										}}<span><i class="lnr-arrow-u cursor-pointer"></i><i
											class="lnr-arrow-dow cursor-pointer"></i></span></div>
								</th>
								<th class="text-xs">Actions</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(user, i) in GET_USERS_COMPANY.results" :key="i">
								<td class="text-xs font-light">
									<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
										<span class="cursor-pointer" @click="getCompanyUserID(user.id)"
											  v-if="user.company">{{ user.company.name | capitalizeNames }}</span>
										<span class="cursor-pointer" @click="getCompanyUserID(user.id)"
											  v-else>---</span>
									</el-tooltip>
								</td>
								<td class="text-xs font-light">
									<span v-if="user.company">{{ user.company.company_id || null }}</span>
									<span v-else>---</span>
								</td>
								<td class="flex space-x-4">
									<el-tooltip :content="$t('enterCompanyInfo')" placement="top">
										<span @click="getCompanyUserID(user.id)"
											  class="text-base cursor-pointer iconly-brokenEdit icon-color"></span>
									</el-tooltip>
									<el-tooltip :content="$t('noteHovering')" placement="top">
										<span @click="checkCompanyNote(user.id, 'Company')"
											  class="text-base cursor-pointer iconly-brokenPaper-Plus icon-color"></span>
									</el-tooltip>
									<el-tooltip :content="$t('deleteHovering')" placement="top">
										<span @click="disconnectCompany(user.id)"
											  class="text-base cursor-pointer iconly-brokenDelete icon-color"></span>
									</el-tooltip>
								</td>
							</tr>
							<!-- <tr v-if="usersCompany.length < 1" class="odd text-center"><td colspan="5" class="dataTables_empty text-xs" valign="top">{{ $t('noDataTable') }}</td></tr> -->
							</tbody>
						</table>
						<div class="float-right mb-5" v-if="GET_USERS_COMPANY.results">
							<paginate
								:page-count="GET_USERS_COMPANY.total_page || GET_USERS_COMPANY.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination'">
							</paginate>
						</div>
						<!-- <board ref="board" :board="activeBoard" v-show="activeBoard" /> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import debounce from 'lodash/debounce'
// import Board from './../../Sticky/components/Board'
import bus from './../../bus.js'
import Paginate from 'vuejs-paginate'

export default {
	name: "Dashboard",
	components: {
		paginate: Paginate,
	},
	data() {
		return {
			usersCompany: [],
			copyAllCompanies: [],
			// dashboardMetrics : {
			//     affiliate_type: null,
			//     total_accountants: 0,
			//     total_customers: 0,
			//     total_hours_saved: 0,
			//     total_kickbacks_and_discounts: null,
			//     total_money_saved: 0,
			//     total_subscriptions: 0,
			//     total_transactions: 0,
			// },
			activeBoardIndex: 0,
			unsavedChanges: false,
			boards: [],
			updateUser: false,
			createNote: false,
			userIdNote: null,
			noteId: null,
			userAgent: null,
		}
	},
	computed: {
		// activeBoard () {
		//     return this.boards[this.activeBoardIndex] || false
		// },
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER_FIRM_ID: 'customer/GET_USER_FIRM_ID',
			GET_DASHBOARD_METRICS: 'accountant/GET_DASHBOARD_METRICS',
			GET_USERS_COMPANY: 'accountant/GET_USERS_COMPANY',
			GET_USER: 'customer/GET_USER',
		}),
		...mapState({
			DASHBOARD_METRIC: state => state.accountant.dashboardMetrics
		})
	},
	created() {
	},
	mounted() {
		const self = this
		this.getAuthUser()
		if (this.GET_USER_FIRM_ID) {
			// console.log('firm get')
			this.getAccountantMetrics(this.GET_USER_FIRM_ID)
			this.getConnectCompany(this.GET_USER_FIRM_ID)
		} else {
			// console.log('firm not get')
			this.getAccountantMetrics(null)
			this.getUsersCompany()
		}

		window.Bus.$on('refetch-company', _ => {
			if (self.GET_USER_FIRM_ID) {
				self.getConnectCompany(self.GET_USER_FIRM_ID)
			} else {
				self.getUsersCompany()
			}
		})


	},
	methods: {
		getAuthUser() {
			const URL = this.$services.endpoints.GET_USER
			this.$store.dispatch('customer/getAuthUser', {URL})
				.then(res => {
					if (res.data.success) {
					}
				}).catch(err => {
				// console.log(err)
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAuthUser()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					}
				}
			})
		},
		getConnectCompanies(partnerId) {
			this.$store.dispatch('master_accountant/getConnectCompany', {URL: this.$services.endpoints.MASTER_ACCOUNTANT_COMPANIES + `${partnerId}`}).then(_ => {
				this.vLoadingCompany = false
			})
		},
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.filterCompanies(event)
			} else {
				// this.usersCompany = this.copyAllCompanies
				this.$store.commit('accountant/FILTER_DEFAULT')
			}
		}, 500),
		filterCompanies(event) {
			// console.log(event)
			this.$store.commit('accountant/FILTER_COMPANY', event.target.value)
		},
		disconnectCompany(user) {
			window.Bus.$emit('disconnect-company', user)
		},
		getAccountantMetrics(partnerId = null) {
			const URL = partnerId ? this.$services.endpoints.GET_ACCOUNTING_FIRM_METRICS + `${JSON.parse(partnerId).partner}` : this.$services.endpoints.GET_ACCOUNTANT_METRICS
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_ACCOUNTANT_METRICS)
			this.$store.dispatch('accountant/getAccountantMetrics', {URL})
			// .then(res => {
			//     if (res.data.success) {
			//         this.dashboardMetrics = res.data.data
			//     }
			// }).catch((_) => {
			// })
		},
		checkCompanyNote(userId, agent) {
			this.$services.helpers.notification(this.$t('pleaseWait'), 'progress', this)
			const URL = this.$services.endpoints.COMPANY_NOTES + `?user_id=${userId}`
			this.$store.dispatch('accountant/checkCompanyNote', URL)
				.then(res => {
					bus.$emit('hide-notify-me', '')
					if (res.data.success) {
						if (res.data.data.length > 0) {
							bus.$emit('open-sticky', {
								userId,
								updateUser: true,
								agent,
								noteID: res.data.data[0].id,
								message: res.data.data[0].message
							})
						} else {
							bus.$emit('open-sticky', {
								userId,
								updateUser: false,
								agent,
								message: ''
							})
							// this.createNote = true
							// this.updateUser = false
							// this.userIdNote = userId
							// this.companyID = userId
							// this.userAgent = agent
							// this.$refs.board.addNote('neutral')
						}
					}
				}).catch((_) => {

			})
		},
		getUsersCompany() {
			// this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
			// this.$services.axios.get(this.$services.endpoints.GET_USER_BY_MANAGER)
			this.$store.dispatch('accountant/getUsersCompany', {})
				.then(_ => {
				}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getUsersCompany()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								// window.location.reload()
							})
					}
				}
			})
		},
		changePageNumber(num) {
			if (this.GET_USER_FIRM_ID) {
				this.$store.dispatch('accountant/getConnectCompany', {URL: this.$services.endpoints.IN_HOUSE_GET_FIRM_COMPANIES + `${JSON.parse(this.GET_USER_FIRM_ID).partner}?page=${num}`})
			} else {
				this.$store.dispatch('accountant/getUsersCompany', {URL: this.$services.endpoints.GET_USER_BY_MANAGER + `?page=${num}`})
			}
		},
		getConnectCompany(partnerId) {
			// this method handle fetching of company attached to an accountant
			this.$store.dispatch('accountant/getConnectCompany', {URL: this.$services.endpoints.IN_HOUSE_GET_FIRM_COMPANIES + `${JSON.parse(partnerId).partner}`})
		},
		ascendingOrderName() {
			this.$store.commit('accountant/ASCENDING_ORDER_NAME')
		},
		descendingOrderName() {
			this.$store.commit('accountant/DESCENDING_ORDER_NAME')
		},
		getCompanyUserID(userID) {
			this.$store.commit('customer/SET_USER_COMPANY_ID', userID)
			setTimeout(() => {
				// window.location.href = '/automatisera'
				window.open('/automatisera', '_blank')
			}, 1000)
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
@import "~vue-tour/dist/vue-tour.css";

.bg-custom-theme-blue {
	background: #F1FBFE !important;
	border: 0.5px solid #4FCBF2;
}

.text-b-color-theme {
	color: #4FCBF2;
}

.bg-theme-colord {
	background: #FEF1F5 !important;
	border: 0.5px solid $theme-primary-color;
}

.text-color-themed {
	color: $theme-primary-color;
}

.bg-theme-blue {
	background: #F2F0FF !important;
	border: 0.5px solid $theme-secondary-color;
}

.text-color-theme-blue {
	color: $theme-secondary-color;
}

.bg-theme-yellow {
	background: #FEFAF1 !important;
	border: 0.5px solid #F2C54F;
}

.text-color-theme-yellow {
	color: #F2C54F;
}

.bg-theme-pink {
	background: #FEF1FB !important;
	border: 0.5px solid #F24FCD;
}

.text-color-theme-pink {
	color: #F24FCD;
}

.bg-theme-green {
	background: #F2FDF5 !important;
	border: 0.5px solid #1ED050;
}

.text-color-theme-green {
	color: #1ED050;
}

.bg-theme-org {
	background: #FEF6F1 !important;
	border: 0.5px solid #F28A4F;
}

.text-color-theme-org {
	color: #F28A4F;
}

.bg-theme-d-blue {
	background: #F1F5FE !important;
	border: 0.5px solid #4F81F2;
}

.text-color-theme-d-blue {
	color: #4F81F2;
}

.input-icons i {
    position: absolute;
    margin: 7px;
	z-index: 10;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
	position: relative;
}

.custom-search {
	padding-left: 1.5rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.bg-custom-theme-teal {
	background: #85CED2 !important;
}

.bg-custom-theme-green {
	background: #BBE5AC !important;
}

.bg-custom-theme-gray {
	background-color: #F4F6EC !important;
}

.bg-custom-theme-pink {
	background: #E19CD2 !important;
}

.bg-custom-deep-green {
	background: #85C5AE !important;
}

.bg-custom-light-bl {
	background: #C5ACE5 !important;
}

.card-text-num {
	color: #7E3469 !important;
	font-weight: 800;
	float: right;
}

.bg-custom-light-org {
	background: #E5B0AC !important;
}

.bg-custom-light-brown {
	background: #E5CFAC !important;
}

.unset-opa-increa-size {
	opacity: unset !important;
	font-size: 1.3rem;
}

.widget-chart .widget-numbers {
	margin: unset !important;
}
</style>
